<template>
  <div class="hero-top">
    <div class="hero-top__wrapper">
      <img class="hero-top__background"
           :src="backgroundImage"
           alt/>
      <div class="hero-top__limiter">
        <slot name="hero-top-breadcrumbs"/>
        <slot name="hero-top-title"/>
        <slot name="hero-top-descriptions"/>
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeroTop',
  props: {
    backgroundImage: {},
  },
};
</script>
